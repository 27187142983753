import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

export default class Home extends Component {
  render() {
    return (
      <div className="home">
        <Header location={this.props.location} />
        <CarouselSlider />
        <WrapperContiner />
        <Footer />
      </div>
    );
  }
}

class CarouselSlider extends React.Component {
  render() {
    return (
      <div>
        {/* start: Slider */}
        <div className="slider-wrapper">
          <div id="da-slider" className="da-slider">
            <div className="da-slide">
              <h2>We build software</h2>
              <p>
                Devops and infrastructure as code, content management systems,
                robust API development, cutting edge web development - if you
                can think of it, we can build it.
              </p>
              <a href="/services" className="da-link">
                Read more
              </a>
              <div className="da-img">
                <img
                  src="/assets/img/parallax-slider/twitter.png"
                  alt="Twitter"
                />
              </div>
            </div>
            <div className="da-slide">
              <h2>Cloud Solutions</h2>
              <p>
                We are masters of all things cloud. Our catalog of pre-built
                cloud solutions enable rapid application deployment, testing and
                delivery.
              </p>
              <a href="/services" className="da-link">
                Read more
              </a>
              <div className="da-img">
                <img
                  src="/assets/img/parallax-slider/responsive.png"
                  alt="Read More"
                />
              </div>
            </div>
            <div className="da-slide">
              <h2>Augmented Teams</h2>
              <p>
                We provide smart hybrid teams that can augment your existing in
                house development teams providing the highest quality output at
                significantly lower costs.
              </p>
              <a href="/services" className="da-link">
                Read more
              </a>
              <div className="da-img">
                <img
                  src="/assets/img/parallax-slider/html5.png"
                  alt="Read More"
                />
              </div>
            </div>
            <div className="da-slide">
              <h2>Cloud & Devops</h2>
              <p>
                We embrace the power of the cloud and build fully automated
                workflows for continuous integration and continuous delivery of
                your applications.
              </p>
              <a href="/services" className="da-link">
                Read more
              </a>
              <div className="da-img">
                <img
                  src="/assets/img/parallax-slider/css3.png"
                  alt="Read More"
                />
              </div>
            </div>
            <nav className="da-arrows">
              <span className="da-arrows-prev"></span>
              <span className="da-arrows-next"></span>
            </nav>
          </div>
        </div>
        {/* end: Slider */}
      </div>
    );
  }
}

class WrapperContiner extends React.Component {
  render() {
    return (
      <div>
        {/*start: Wrapper*/}
        <div id="wrapper">
          {/*start: Container */}
          <div className="container">
            <hr />

            {/* start: Hero Unit - Main hero unit for a primary marketing message or call to action */}
            <div className="hero-unit">
              <h3>
                Raj International, Inc. is a boutique, results driven software
                development company with over twenty-five years of experience in
                leading key software technology and enterprise application
                integration (EAI) projects in the US domestic and international
                markets.
              </h3>
              <p>
                <a href="/contact" className="btn btn-primary btn-large">
                  Contact Us &raquo;
                </a>
              </p>
            </div>
            {/* end: Hero Unit */}

            <hr />

            {/* start: Row */}
            <div className="row">
              {/* start: Icon Boxes */}
              <div className="icons-box-vert-container">
                {/* start: Icon Box Start */}
                <div className="span4">
                  <div className="icons-box-vert">
                    <i className="ico-ok ico-white circle-color-full"></i>
                    <div className="icons-box-vert-info">
                      <h3>Strategizing, Planning and Designing</h3>
                      <p>
                        Tell us how you envision your perfect product…we’ll
                        guarantee it happens. Not sure about some aspects of
                        your product or service? We have experts on staff ready
                        to provide valuable advice.
                      </p>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
                {/* end: Icon Box*/}

                {/* start: Icon Box Start */}
                <div className="span4">
                  <div className="icons-box-vert">
                    <i className="ico-cup  ico-white circle-color-full"></i>
                    <div className="icons-box-vert-info">
                      <h3>Execution, Testing, and Certification</h3>
                      <p>
                        Work with us, you’ll get our total undivided attention.
                        We provide product managers and developers solely
                        dedicated to building and optimizing your product.
                      </p>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
                {/* end: Icon Box */}

                {/* start: Icon Box Start */}
                <div className="span4">
                  <div className="icons-box-vert">
                    <i className="ico-ipad ico-white circle-color-full"></i>
                    <div className="icons-box-vert-info">
                      <h3>Launching, Management, and Growth</h3>
                      <p>
                        Launch is the most exciting part of entire process,
                        which delivers and transforms your online presence.
                        We’ll support you 24/7 throughout the launch and
                        establishment process.
                      </p>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
                {/* end: Icon Box */}
              </div>
              {/* end: Icon Boxes */}
              <div className="clear"></div>
            </div>
            {/* end: Row */}

            <hr />

            {/* start: Row */}
            <div className="row">
              <div className="span9">
                <div className="title">
                  <h3>Latest Works</h3>
                </div>
                {/* start: Row */}
                <div className="row">
                  <div className="span3">
                    <div className="picture">
                      <a
                        href="#"
                        rel="image"
                        title="Real Estate"
                        class="inactiveLink"
                      >
                        <img src="/assets/img/car.jpg" alt="Real Estate" />
                        <div className="image-overlay-link"></div>
                      </a>
                    </div>
                    <div className="item-description">
                      <h4>
                        <a href="#" class="inactiveLink">Premier Nationwide Healthcare</a>
                      </h4>
                      <p>
                        Piloted cloud native CMS solutions as part of a large Cloud transformation effort within the organization. Our product offerings were managed with a devops centric methodology and met customer demand 800% faster than traditional IT solutions.
                      </p>
                      <h4>Tech Stack: Wordpress on AWS</h4>
                      <p></p>
                    </div>
                  </div>

                  <div className="span3">
                    <div className="picture">
                      <a href="#" class="inactiveLink">
                        <img
                          src="/assets/img/web_app1.jpg"
                          alt="Media Startup"
                        />
                        <div className="image-overlay-link"></div>
                      </a>
                    </div>
                    <div className="item-description">
                      <h4>
                        <a href="#" title="Media Startup" class="inactiveLink">
                          Media Startup
                        </a>
                      </h4>
                      <p>
                        Built and operated the product for a media startup powering video competitions online. The technology stack was Laravel PHP, docker, running on the Kubernetes cluster on the Google Cloud Platform. The application followed the https://12factor.net/ standard for modern app development.
                      </p>
                      <h4>Tech Stack: Laravel on Kubernetes</h4>
                      <p></p>
                    </div>
                  </div>

                  <div className="span3">
                    <div className="picture">
                      <a
                        href="/assets/img/mobile_app1.jpg"
                        rel="image"
                        title="US Government Contract"
                        class="inactiveLink"
                      >
                        <img
                          src="/assets/img/mobile_app1.jpg"
                          alt="US Government Contract"
                        />
                        <div className="image-overlay-link"></div>
                      </a>
                    </div>
                    <div className="item-description">
                      <h4>
                        <a href="#" class="inactiveLink">
                          Real Estate
                        </a>
                      </h4>
                      <p>
                        Developed a .NET based solution for a mid-atlantic based Real Estate firm. Our solution was designed to be consistent and scalable and serviced over 5000 mortgage approval workflows a month.
                      </p>
                      <h4>Tech Stack: .NET on AWS GovCloud (SOC 2 compliant)</h4>
                      <p></p>
                    </div>
                  </div>
                </div>
                {/* end: Row */}
              </div>

              <div className="span3">
                {/* start: Testimonials*/}
                <div className="testimonial-container">
                  <div className="title">
                    <h3>What Client’s Say</h3>
                  </div>
                  <div className="testimonials-carousel" data-autorotate="3000">
                    <ul className="carousel">
                      <li className="testimonial">
                        <div className="testimonials">
                          Dulles Medical Center is a clinic
                          delivering healthcare services to the private sector.
                          We had outsourced the development of our integrated
                          Medical Services / Medical Records system to a third
                          party vendor and were two years behind schedule and
                          two hundred thousand dollars over budget. Raj
                          International Inc. assisted in quickly mapping out the
                          existing bottlenecks and completely overhauled our
                          systems.
                        </div>
                        <div className="testimonials-bg"></div>
                        <div className="testimonials-author">
                          Victor Railan, <span>CEO</span>
                        </div>
                      </li>

                      <li className="testimonial">
                        <div className="testimonials">
                          Our Real Estate firm needed a web based system that
                          was capable of handling high volumes of traffic. Raj
                          International Inc. worked with our stakeholders and
                          developed a robust user friendly application that has
                          seen tremendous growth since its launch. Because of
                          our success in working with Raj International Inc., we
                          have contracted the firm as our primary development team for our existing and future IT systems .{" "}
                        </div>
                        <div className="testimonials-bg"></div>
                        <div className="testimonials-author">
                          Randy, MasterServ Financial
                        </div>
                      </li>

                      <li className="testimonial">
                        <div className="testimonials">
                          We are software shop based in the Los Angeles area. We
                          work with upcoming startups in the Los Angeles area to
                          grow and maintain their code base. We worked with Raj
                          International Inc. to implement coding guidelines,
                          infrastructure architecture, database designs and
                          cloud hosting solutions. The augmented development team provided by Raj International Inc. allowed us to double our velocity and get through our product backlog to meet rapidly developing customer needs.
                        </div>
                        <div className="testimonials-bg"></div>
                        <div className="testimonials-author">
                          thatsgoodsoftware, <span>CTO</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* end: Testimonials*/}
              </div>
            </div>
            {/* end: Row */}

            <hr />
            <div className="row">
              <div className="title">
                <h3>Our Clients</h3>
              </div>
              <div className="span3"></div>
              <div className="span3">
                <a
                  href="https://healthy.kaiserpermanente.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="/assets/img/rajinternational/home/clients/kaiser.png"
                    alt="Kaiser Permanente"
                  />
                </a>
              </div>
              <div className="span3">
                <a
                  href="https://www.crunchbase.com/organization/copromote"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="/assets/img/rajinternational/home/clients/copromote.png"
                    alt="CoPromote | Crunchbase"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* end Clients List */}
          <hr />
        </div>
        {/*end: Container*/}
      </div>
    );
  }
}
