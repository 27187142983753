import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

export default class About extends Component {
  render() {
    return (
      <div className="about">
        <Header location={this.props.location} />
        <WrapperContainer />
        <Footer />
      </div>
    );
  }
}

class WrapperContainer extends React.Component {
  render() {
    return (
      <div>
        {/* start: Page Title */}
        <div id="page-title">
          <div id="page-title-inner">
            {/* start: Container */}
            <div className="container">
              <h2>
                <i className="ico-stats ico-white"></i>About Us
              </h2>
            </div>
            {/* end: Container  */}
          </div>
        </div>
        {/* end: Page Title */}
        {/*start: Wrapper*/}
        <div id="wrapper">
          {/*start: Container */}
          <div className="container">
            {/*start: Row */}
            <div className="row">
              <div className="span8">
                {/* start: About Us */}
                <div id="about">
                  <div className="title">
                    <h3>About Us</h3>
                  </div>
                  <p>
                    Founded in April, 1987 by 2 veterans of MCI telecommunications, Raj International Inc. is a US based boutique software development shop.

                    The company was born out of a strong desire to be a disruptor in the product development space. Our founders envisioned a company free of red tape and company politics stifling innovation and leading to bad technology decisions. One of our founders, Ken, joked in the early days "do less powerpoint and more code". With that motto, the company today remains focussed on what matters - high quality, customer centric software products built by rockstar software developers.
                  </p>
                  <p>
                    Raj International Inc. has a talented pool of
                    high-technology experts. We possess a wide span of
                    well-balanced knowledge, which includes complex
                    architectures and platforms. We cover the full systems development life cycle, from inception to final implementation and vendor management experience.
                  </p>
                  <p>
                    Raj International Inc. is adept at working in fragmented business environments and proficient at rescuing faltering projects to meet company timelines and objectives. We possesses broad strategic skills including
                    smarter staff augmentation, tailored finance, customized project planning and end to end product development and hosting. Our customers often comment that the expertise we provide extends beyond traditional IT operational boundaries.
                  </p>
                  <p>
                    Functional competencies of the company include software and systems architecture and design, business case development, software testing, enterprise application integration, cross-functional team integration, project management, web systems development, and client and customer management.
                  </p>
                </div>
                {/* end: About Us */}
                {/* start: History */}
                <div id="history">
                  <div className="title">
                    <h3>Technology</h3>
                  </div>
                  <p>
                    <img src="/assets/img/rajinternational/technology.png" />
                  </p>
                  <p>
                    We also partner with innovative technology startups and are uniquely poised to provide technical, hosting and business management consulting to upcoming startups. We encourage startups to approach us with their business model and we provide the developer and management resources necessary to take the startup idea from conception to a fully functioning software platform.
                  </p>
                </div>
                {/* end: History */}

                {/* start: Team */}
                <div id="team">
                  <div className="title">
                    <h3>Team</h3>
                  </div>
                  {/* start: Row */}
                  <div className="row">
                    {/* start: About Member */}
                    <div className="span4">
                      <img src="/assets/img/rajinternational/ken-kreuger.jpeg" />
                      <div className="team-name">
                        Ken Krueger <span> CEO</span>
                      </div>
                      <p>
                        Ken has had a long and diverse career in IT spanning
                        over 25 years. In this time, Ken has worked as a
                        developer, project manager and product innovator. As
                        part of Raj International, Ken is responsible for
                        allocating resources and opening up new markets for the
                        firm. Ken has been crucial in establishing out strong
                        client base across the Atlantic seaboard and the
                        American midwest and is currently involved in securing
                        long term contracts in the South East Asian markets.
                      </p>
                      <div className="social_profiles">
                        <ul className="social-bookmarks">
                          <li className="linkedin">
                            <a
                              href="https://www.linkedin.com/in/kckrueger/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              linkedin
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* end: About Member */}

                    {/* start: About Member */}
                    <div className="span4">
                      <img
                        src="/assets/img/rajinternational/mihad-alzayat.jpeg"
                      />
                      <div className="team-name">
                        Mihad Alzayat <span> Advisor</span>
                      </div>
                      <p>
                        Mihad is a Devops and Infrastructure specialist that
                        evangelises infrastructure as code both in the data
                        center and in the cloud. He has a wealth of exprience
                        migrating customers from legacy infrastructure to cloud
                        native services.
                      </p>
                    </div>
                    {/* end: About Member */}

                    {/* start: About Member */}
                    <div className="span4">
                      <img src="/assets/img/rajinternational/bobby-singh.png" />
                      <div className="team-name">
                        Penn Bobby Singh <span> CFO</span>
                      </div>
                      <p>
                        Before joining Raj International, Bob was enjoyed a 2
                        decade career as a successful Software Developer,
                        Project Manager, Project Lead and an Enterprise
                        Architect. Bob managed teams of upto 85 people based
                        in the United States, France and the United Kingdom.
                        Bob is one our most diverse team members and has also published a series of short stories and novels (amazon link below).
                      </p>
                      <div className="social_profiles">
                        <ul className="social-bookmarks">
                          <li className="twitter">
                            <a
                              href="https://www.amazon.com/Perennial-Stream-Penn-Bobby-Singh/dp/1935188399"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              twitter
                            </a>
                          </li>
                          <li className="linkedin">
                            <a
                              href="https://www.linkedin.com/in/bob-bapna-0538661"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              linkedin
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* end: About Member */}

                    {/* start: About Member */}
                    <div className="span4">
                      <img
                        src="/assets/img/rajinternational/ben-gelsey.jpeg"
                        alt="team member"
                      />
                      <div className="team-name">
                        Ben Gelsey <span> Advisor</span>
                      </div>
                      <p>
                        Ben is a technical thought leader and advisor with core
                        interests in programming, e-marketing and digital
                        nomad-ing. With over a decade of Ruby on Rails
                        experience, he can can build Rails apps in his sleep. He
                        specialized in APIs development (for mobile apps or
                        otherwise) and has opiniated design patterns + toolkits
                        to get projects shipped at lightening pace.
                      </p>
                      <div className="social_profiles">
                        <ul className="social-bookmarks">
                          <li className="linkedin">
                            <a
                              href="https://www.linkedin.com/in/ben-gelsey-99173337/"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              linkedin
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* end: About Member */}

                  </div>
                  {/* end: Row */}
                </div>
                {/* end: Team */}
              </div>
              <div className="span4">
                {/* start: Sidebar */}
                <div id="sidebar">
                  {/* start: Skills */}
                  <div className="title">
                    <h3>Our Skills</h3>
                  </div>
                  <ul className="progress-bar">
                    <li>
                      <h5>Custom Software ( 100% )</h5>
                      <div className="meter">
                        <span style={{ width: "100%" }}></span>
                      </div>
                    </li>
                    <li>
                      <h5>CMS, Blogs, E-commerce ( 100% )</h5>
                      <div className="meter">
                        <span style={{ width: "100%" }}></span>
                      </div>
                    </li>
                    <li>
                      <h5>Web Design and Development ( 100% )</h5>
                      <div className="meter">
                        <span style={{ width: "100%" }}></span>
                      </div>
                    </li>
                    <li>
                      <h5>Data Analytics ( 100% )</h5>
                      <div className="meter">
                        <span style={{ width: "100%" }}></span>
                      </div>
                    </li>
                    <li>
                      <h5>Cloud & Devops ( 100% )</h5>
                      <div className="meter">
                        <span style={{ width: "100%" }}></span>
                      </div>
                    </li>
                    <li>
                      <h5>CI CD ( 100% )</h5>
                      <div className="meter">
                        <span style={{ width: "100%" }}></span>
                      </div>
                    </li>
                  </ul>
                  {/* end: Skills */}
                  {/* start: Testimonials*/}
                  <div className="testimonial-container">
                    <div className="title">
                      <h3>What Client’s Say</h3>
                    </div>
                    <div
                      className="testimonials-carousel"
                      data-autorotate="3000"
                    >
                      <ul className="carousel">
                        <li className="testimonial">
                          <div className="testimonials">
                            Dulles Medical Center is an enterprise focusing on
                            delivering healthcare services to the private
                            sector. We had outsourced the development of our
                            integrated Medical Services / Medical Records system
                            to a third party vendor and were two years behind
                            schedule and two hundred thousand dollars over
                            budget.Raj International Inc. assisted in quickly
                            mapping out the existing bottlenecks and completely
                            overhauled our systems.
                          </div>
                          <div className="testimonials-bg"></div>
                          <div className="testimonials-author">
                            Victor Railan, <span>CEO</span>
                          </div>
                        </li>
                        <li className="testimonial">
                          <div className="testimonials">
                            Our Real Estate firm needed a web based system that
                            was capable of handling high volumes of traffic.Raj
                            International Inc. worked with our stakeholders and
                            developed a robust user friendly application that
                            has seen tremendous growth since its launch. Because
                            of our success in working with Raj International
                            Inc., we have contracted the firm as our permanent
                            support staff for our existing and future IT systems
                            for the next 2 years.{" "}
                          </div>
                          <div className="testimonials-bg"></div>
                          <div className="testimonials-author">
                            MasterServ Financial
                          </div>
                        </li>
                        <li className="testimonial">
                          <div className="testimonials">
                            We are Software shop based in the Los Angeles area.
                            We work with upcoming startups in the Los Angeles
                            area to grow and maintain their code base. We worked
                            with Raj International Inc. to implement coding
                            guidelines, infrastructure architecture, database
                            designs and cloud hosting solutions that allows our
                            clients to seamlessly handle anywhere between 5000
                            to 5 million users with 99% Service uptime.
                          </div>
                          <div className="testimonials-bg"></div>
                          <div className="testimonials-author">
                            thatsgoodsoftware, <span>CTO</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* end: Testimonials*/}
                  {/* end: Testimonials*/}
                </div>
                {/* end: Sidebar */}
              </div>
            </div>
            {/*end: Row*/}
          </div>
          {/*end: Container*/}
          {/*start: Container */}
          <div className="container">
            <hr />
            <div className="row">
              <div className="title">
                <h3>Our Clients</h3>
              </div>
              <div className="span3"></div>
              <div className="span3">
                <a
                  href="https://healthy.kaiserpermanente.org"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="/assets/img/rajinternational/home/clients/kaiser.png"
                    alt="Kaiser Permanente"
                  />
                </a>
              </div>
              <div className="span3">
                <a
                  href="https://www.crunchbase.com/organization/copromote"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src="/assets/img/rajinternational/home/clients/copromote.png"
                    alt="CoPromote | Crunchbase"
                  />
                </a>
              </div>
            </div>
            <hr />
          </div>
          {/*end: Container*/}
        </div>
        {/* end: Wrapper  */}
      </div>
    );
  }
}
