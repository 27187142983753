import React, { Component } from 'react';

export default class Header extends Component {

  render() {
    var location = this.props.location
    return (
      <header>
        {/* start: Container */}
        <div className="container">
          {/* start: Navbar */}
          <div className="navbar navbar-inverse">
            <div className="navbar-inner">
              <a className="btn btn-navbar" data-toggle="collapse" data-target=".nav-collapse">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </a>
              <a className="brand" href="/"><i className="ico-charts circle"></i><span>Raj</span> International Inc.</a>
              <div className="nav-collapse collapse">
                <ul className="nav">
                  <li className={(location.pathname === '/' || location.pathname === '/home' ? 'active' : '')}><a href="/">Home</a></li>
                  <li className={(location.pathname === '/about' ? 'active' : '')}><a href="/about">About</a></li>
                  <li className={(location.pathname === '/services' ? 'active' : '')}><a href="/services">Services</a></li>
                  {/* <li className={(location.pathname === '/blog' ? 'active' : '')}><a href="/blog">Blog</a></li> */}
                  <li className={(location.pathname === '/contact' ? 'active' : '')}><a href="/contact">Contact</a></li>
                </ul>
              </div>
            </div>
          </div>
          {/* end: Navbar */}
        </div>
        {/* end: Container*/}
      </header>

    )
  }
}
