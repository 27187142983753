import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

export default class Services extends Component {
  render() {
    return (
      <div className="services">
        <Header location={this.props.location} />
        <WrapperConatainer />
        <Footer />
      </div>
    );
  }
}

class WrapperConatainer extends React.Component {
  render() {
    return (
      <div>
        {/* start: Page Title */}
        <div id="page-title">
          <div id="page-title-inner">
            {/* start: Container */}
            <div className="container">
              <h2>
                <i className="ico-settings ico-white"></i>Services
              </h2>
            </div>
            {/* end: Container  */}
          </div>
        </div>
        {/* end: Page Title */}

        {/*start: Wrapper*/}
        <div id="wrapper">
          {/*start: Container */}
          <div className="container">
            {/* start: Row */}
            <div className="row">

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-embed-close ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Custom Software</h3>
                  </div>
                  <p>
                    If you think of it, Raj International can build it. We have worked with countless industries (healthcare, fintech, logistics, media, to name a few) and provided solutions ranging from web applications to highly scalable API development.
                  </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-file-import ico-color circle-color big"></i>
                  <div className="title">
                    <h3>CMS, Blogs, E-commerce</h3>
                  </div>
                  <p>
                    We support and extend many of the market leader solutions like Shopify, Squarespace, Wordpress, Wix, Adobe Suite, and others. We also custom build security focussed state of the art CMS and ecommerce solutions for industry specific needs.
                  </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-imac ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Web Design &amp; Development</h3>
                  </div>
                  <p>
                    We use the latest and best practices in web design and development and offer beautiful frontend designs coupled with robust backend technologies. Our technologies in a
                    snapshot: HTML 5, CSS 3, Javascript, PHP, Ruby on Rails, Python, .NET core.
                  </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-database-plus ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Data Analytics</h3>
                  </div>
                  <p>
                    We provide cheap, reliable hosting on our own cloud platform integrated with data analytics. Enjoy 99.9% uptime on the same infrastructure
                    that we host our own website and products.
                  </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-cloud ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Cloud & Devops</h3>
                  </div>
                  <p>
                    It takes cloud expertise to weave vibrant technology products with the array of services offered by cloud providers. We can build and operate infrastructure, as code, in your data center or the cloud (AWS/GCP/Rackspace/Azure).
                  </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-embed-close ico-color circle-color big"></i>
                  <div className="title">
                    <h3>CI / CD</h3>
                  </div>
                  <p>
                    We can automate your application through continuous deployments, setup 1 click environments for QA / testing, and setup quality gates and code standards for continuous integration.
                  </p>
                  <div className="clear"></div>
                </div>
              </div>

              {/* end: Row */}
            </div>
            {/*end: Container*/}
          </div>
          {/* end: Wrapper
<link rel="stylesheet" type="text/css" href="css/fonts.css">*/}
        </div>
      </div>
    );
  }
}
