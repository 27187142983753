import React, { useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

const markers = [
  {
    id: 1,
    name: "1815 Gilson Street, Falls Church, VA 22043",
    position: { lat: 38.91729794321159, lng: -77.1965237004637 }
  },
  {
    id: 2,
    name: "1775 Tysons Blvd, Tysons Corner, VA 22102",
    position: { lat: 38.92357083070971, lng: -77.22049084562721 }
  },
  {
    id: 3,
    name: "109 East 17th Street, Cheyenne, Wyoming 82001, USA",
    position: { lat: 41.13395852312425, lng: -104.81431790016919 }
  },
  {
    id: 4,
    name: "Crystal IT Park, SEZ , STP-1, 5th Floor, Ring Road, Indore, Madhya Pradesh 452001",
    position: { lat: 22.685829234531667, lng: 75.87225799742961 }
  }
];

function MarkerMap() {
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100vw", height: "75vh" }}
    >
      {markers.map(({ id, name, position }) => (
        <Marker
          key={id}
          position={position}
          onClick={() => handleActiveMarker(id)}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{name}</div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  );
}

export default MarkerMap;