import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
//import appRoutes from "./routes/routes.jsx";
import Home from "./views/Home";
import About from './views/About';
import Services from "./views/Services";
//import Blog from "./views/Blog";
import Contact from "./views/Contact";
import Projects from "./views/Projects";
import RealEstate from "./views/RealEstate";
import USGovernmentContract from "./views/USGovernmentContract";
import MediaStartup from "./views/MediaStartup";
import ReactDOM from "react-dom/client";
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

const root = ReactDOM.createRoot(document.getElementById("root"));
//const appRoutesRender = Object.keys(appRoutes).map(({path, component}, key) => <Route exact path={path} component={component} key={key}  location={path}/>);
root.render(
  <React.StrictMode>
  <BrowserRouter>
    <Routes>
    <Route path="/" element={<Home location="/" />} />
    <Route path="/about" element={<About location="/about" />} />
    <Route path="/services" element={<Services location="/services" />} />
    {/* <Route path="/blog" element={<Blog location="/blog" />} /> */}
    <Route path="/contact" element={<Contact location="/contact" />} />
    <Route path="/projects" element={<Projects location="/projects" />} />
    <Route path="/real_estate" element={<RealEstate location="/real_estate" />} />
    <Route path="/us_government_contract" element={<USGovernmentContract location="/us_government_contract" />} />
    <Route path="/media_startup" element={<MediaStartup location="/media_startup" />} />
    </Routes>
  </BrowserRouter>,
  </React.StrictMode>
);