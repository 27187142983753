import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";

export default class USGovernmentContract extends Component {
  render() {
    return (
      <div className="usgovernmentcontract">
        <Header location={this.props.location} />
        <WrapperContainer />
        <Footer />
      </div>
    );
  }
}

class WrapperContainer extends React.Component {
  render() {
    return (
      <div>
        {/* start: Page Title */}
        <div id="page-title">
          <div id="page-title-inner">
            {/* start: Container */}
            <div className="container">
              <h2>
                <i className="ico-keynote ico-white"></i>US Government Contract
              </h2>
            </div>
            {/* end: Container  */}
          </div>
        </div>
        {/* end: Page Title */}

        {/*start: Wrapper */}
        <div id="wrapper">
          {/*start: Container */}
          <div className="container">
            {/* start: Flexslider */}
            <div className="slider">
              <div id="flex1" className="flexslider home">
                <ul className="slides">
                  <li>
                    <img src="/assets/img/slider/slider1.jpg" alt="" />
                    <div className="slide-caption n">
                      <h3>US Government Contract</h3>
                    </div>
                  </li>

                  <li>
                    <img src="/assets/img/slider/slider2.jpg" alt="" />
                    <div className="slide-caption">
                      <h3>US Government Contract</h3>
                    </div>
                  </li>

                  <li>
                    <img src="/assets/img/slider/slider3.jpg" alt="" />
                    <div className="slide-caption">
                      <h3>US Government Contract</h3>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/* end: Flexslider */}

            <hr />

            {/* start: Row */}
            <div className="row">
              <div className="span2">
                <ul className="project-info">
                  <li>
                    <strong>Client:</strong> Google
                  </li>
                  <li>
                    <strong>Date:</strong> August 2019
                  </li>
                </ul>
                <a
                  href="#"
                  className="button color launch"
                  style={{ display: "none" }}
                >
                  Project Page
                </a>
              </div>

              <div className="span10">
                <p>Technologies: Migration, METIS, FEAF</p>
                <p>
                  Developed an Enterprise Management System for the Federal
                  Energy Regulatory Commission (FERC). We streamlined the IT
                  management systems and saved FERC millions of dollars in
                  management costs.
                </p>
              </div>
            </div>
            {/* end: Row */}
            <hr />
            {/* start: Row */}
          </div>
        </div>
      </div>
    );
  }
}
