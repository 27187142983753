import React, { Component } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import MarkerMap from './MarkerMap';

export default class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <Header location={this.props.location} />
        <MarkerMap />
        <WrapperConatiner />
        <Footer />
      </div>
    )
  }
}

class MapConatiner extends React.Component {
  render() {
    return (
      <div>
        {/* start: Map */}
        <div>
          {/* starts: Google Maps */}
          <div id="googlemaps-container-top"></div>
          <div id="googlemaps" className="google-map google-map-full"></div>
          <div id="googlemaps-container-bottom"></div>
          {/* end: Google Maps */}
        </div>
        {/* end: Map */}
      </div>
    )
  }
}

class WrapperConatiner extends React.Component {
  render() {
    return (
      <div>
        <div id="wrapper">
          {/* start: Container */}
          <div className="container">
            {/* start: Row */}
            <div className="row">
              {/* start: Contact Info */}

              {/* end: Contact Info */}
              {/* start: Contact Info */}
              <div className="span3 offset2">
                <div className="title">
                  <h3>US</h3>
                </div>
                <p>
                  1775 Tysons Blvd
                </p>
                <p>
                  Tysons Corner, VA 22102, USA
               </p>
                <p style={{ "margin-top": "20px" }}>
                  109 E 17th Street
                </p>
                <p>
                  Cheyenne, Wyoming 82001, USA
                </p>
                <p>
                </p>
                <p>
                  email: support@rajinternationalinc.com
                </p>

              </div>
              {/* end: Contact Info */}
              {/* start: Contact Info */}
              <div className="span3 offset2">
                <div className="title">
                  <h3>India</h3>
                </div>
                <p>
                  80/88 Jaora Compound
                </p>
                <p>
                  Indore, Madhya Pradesh 452001
                </p>
                <p style={{ "margin-top": "20px" }}>
                  Crystal IT Park (SEZ), 5th Floor (L), Ring Road
                </p>
                <p>
                  Indore, Madhya Pradesh 452001
                </p>
                <p>
                </p>
                <p>
                  email: support@rajinternationalinc.com
                </p>
              </div>
            </div>
          </div>
          {/* end: Row */}

          {/* end: Container */}
        </div >
        {/* end: Wrapper  */}
      </div >
    )
  }
}
